



























































































































































































































import { Component, Vue } from "vue-property-decorator";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import moment from "moment";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import {
  changeCurrencytoNumeric,
  currencyFormat,
} from "@/validator/globalvalidator";

interface StatusPosting {
  label: "New" | "Ready to Post" | "Posted";
  value: "New" | "READY_TO_POST" | "Posted";
}

const Props = Vue.extend({
  props: ["visible", "activeTabPane"],
});
@Component({
  watch: {
    activeTabPane: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal === "Posting Assets") {
          this.dataListStatus = [
            {
              label: "New",
              value: "NEW",
            },
            {
              label: "Ready to Post",
              value: "READY_TO_POST",
            },
          ];
          this.dataListStatus = this.dataListStatus.slice();
        } else if (newVal === "List of Posted Assets") {
          this.dataListStatus = [
            {
              label: "Posted",
              value: "POSTED",
            },
          ];
          this.dataListStatus = this.dataListStatus.slice();
        }
      },
    },
  },
})
export default class ModalFindDataAssetPosting extends Props {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  form: WrappedFormUtils = this.$form.createForm(this, {
    name: "ModalFindDataAssetPosting",
  });
  loading = {
    loadingListAssetBook: false,
  };
  formRules = {
    book: {
      label: "lbl_book",
      name: "book",
      placeholder: "lbl_book_placeholder",
      decorator: ["book"],
    },
    category: {
      label: "lbl_category",
      name: "category",
      placeholder: "lbl_category_placeholder",
      decorator: ["category"],
    },
    description: {
      label: "lbl_description",
      name: "description",
      placeholder: "lbl_description_placeholder",
      decorator: ["description"],
    },
    location: {
      label: "lbl_rack",
      name: "location",
      placeholder: "lbl_rack_placeholder",
      decorator: ["location"],
    },
    locationWarehouse: {
      label: "lbl_warehouse",
      name: "locationWarehouse",
      placeholder: "lbl_warehouse_placeholder",
      decorator: ["locationWarehouse"],
    },
    locationBranch: {
      label: "lbl_branch",
      name: "locationBranch",
      placeholder: "lbl_branch_placeholder",
      decorator: ["locationBranch"],
    },
    purchaseOrderNumber: {
      label: "lbl_purchase_order_number",
      name: "purchaseOrderNumber",
      placeholder: "lbl_purchase_order_number_placeholder",
      decorator: ["purchaseOrderNumber"],
    },
    qty: {
      label: "lbl_qty",
      name: "qty",
      placeholder: "lbl_qty_placeholder",
      decorator: ["qty"],
    },
    lifeMonths: {
      label: "lbl_life_months",
      name: "lifeMonths",
      placeholder: "lbl_life_months_placeholder",
      decorator: ["lifeMonths"],
    },
    acquisitionDate: {
      label: "lbl_acquisition_date",
      name: "acquisitionDate",
      placeholder: "lbl_acquisition_date_placeholder",
      decorator: ["acquisitionDate"],
    },
    assetCost: {
      label: "lbl_asset_cost",
      name: "assetCost",
      placeholder: "lbl_asset_cost_placeholder",
      decorator: ["assetCost"],
    },
    source: {
      label: "lbl_source",
      name: "source",
      placeholder: "lbl_source_placeholder",
      decorator: ["source"],
    },
    serialNumber: {
      label: "lbl_serial_number",
      name: "serialNumber",
      placeholder: "lbl_serial_number_placeholder",
      decorator: ["serialNumber"],
    },
    receiveNumber: {
      label: "lbl_receive_number",
      name: "receiveNumber",
      placeholder: "lbl_receive_number_placeholder",
      decorator: ["receiveNumber"],
    },
    status: {
      label: "lbl_status",
      name: "status",
      placeholder: "lbl_status_placeholder",
      decorator: ["status"],
    },
  };
  dataListStatus: StatusPosting[] = [
    {
      label: "New",
      value: "New",
    },
    {
      label: "Ready to Post",
      value: "READY_TO_POST",
    },
    {
      label: "Posted",
      value: "Posted",
    },
  ];

  onBlur(value, form: string) {
    if (value.target.value && !this.form.getFieldError(`${form}`)) {
      let dataobj = {} as { [props: string]: string };
      dataobj[form] = currencyFormat(value.target.value);
      this.form.setFieldsValue(dataobj);
    }
  }

  onFocus(value, form: string) {
    if (value.target.value && !this.form.getFieldError(`${form}`)) {
      let dataobj = {} as { [props: string]: number };
      dataobj[form] = changeCurrencytoNumeric(value.target.value);
      this.form.setFieldsValue(dataobj);
    }
  }

  handleCancel() {
    this.$emit("handle-visible", false);
  }

  assignSearch(key, value, and): string {
    const objSearch = {
      description: and + `description~*${value}*`,
      book: and + `assetBook.name~*${value}*`,
      serialNumber: and + `serialNumber~*${value}*`,
      unitCode: and + `unitCode~*${value}*`,
      invoiceNumber: and + `invoiceNumber~*${value}*`,
      purchaseOrderNumber: and + `purchaseOrderNumber~*${value}*`,
      location: and + `assetLocation.name~*${value}*`,
      locationWarehouse: and + `assetLocation.warehouse.name~*${value}*`,
      locationBranch:
        and + `assetLocation.warehouse.branchWarehouse.name~*${value}*`,
      qty: and + `unit~${changeCurrencytoNumeric(value)}`,
      lifeMonths: and + `lifeMonths~${changeCurrencytoNumeric(value)}`,
      source: and + `source~${value}`,
      assetCost: and + `assetCost~${changeCurrencytoNumeric(value)}`,
      acquisitionDate:
        and +
        `acquisitionDate>=${moment(value)
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .format()}_AND_acquisitionDate<=${moment(value)
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 59)
          .format()}`,
    };
    if (key && value && objSearch[key]) return objSearch[key];
    else return "";
  }

  dynamicSearch(res, paramsSearch): string {
    let search = paramsSearch;
    Object.keys(res).forEach((key) => {
      if (!search) {
        search = this.assignSearch(key, res[key], "");
      } else {
        search += this.assignSearch(key, res[key], "_AND_");
      }
    });
    return search;
  }

  findData() {
    this.form.validateFields((err, res) => {
      if (err) return;
      const params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
        sorts: "createdDate:desc",
        categoryId: res.category,
        receiveNo: res.receiveNumber,
      };
      if (res.status) {
        params.search = `status~${res.status}`;
        params.search = this.dynamicSearch(res, params.search);
      } else {
        this.dataListStatus.forEach((dataForeach, index) => {
          if (index === 0) {
            params.search = `status~${dataForeach.value}`;
            params.search = this.dynamicSearch(res, params.search);
          } else {
            params.search += `_OR_status~${dataForeach.value}`;
            params.search = this.dynamicSearch(res, params.search);
          }
        });
      }
      this.$emit("handle-find", params);
    });
  }

  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: "ModalFindDataAssetPosting",
    });
  }

  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }
}
